import { useEffect, useCallback } from 'react'

import { Services } from '../utils'

const useRedirectAfterLogin = ({
  autorun = false,
  actionArgs: { returnUrl: returnUrlArg = null } = {},
} = {}) => {
  const redirect = useCallback(
    ({ returnUrl = returnUrlArg } = {}) => Services.redirect({ returnUrl }),
    [returnUrlArg]
  )

  useEffect(() => {
    if (autorun) {
      redirect()
    }
  }, [autorun, redirect])

  return [redirect]
}

export default useRedirectAfterLogin
